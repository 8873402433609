@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  font-family: 'Poppins';
  height: 100%;
}

.transition_all {
  transition: all 0.25s;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  /* -webkit-text-fill-color: #d1d5db !important; */
  /* -webkit-box-shadow: 0 0 0px 1000px #2f4d64 inset; */
  caret-color: #d1d5db;
}

.slate {
  position: relative;
  padding-bottom: 20px;
}

.slate::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100px;
  left: calc(50% - 50px);
  /* border-top: 4px solid #3949ab; */
  border-top: 4px solid #46f7ad;
}

.bg {
  background-image: url('./assets/bg-up.svg'), url('./assets/bg-down.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  background-size: 45%;
}

.bg-dash {
  background-image: url('./assets/bg.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 1000px;
}

.bg-dots {
  background-color: white;
  background-image: url('./assets/bg-dots.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: 1000px;
}

.bg-world {
  background-color: #cbc9ef;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.card {
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.circle_item {
  list-style-image: url('assets/circle-item.svg');
}

.dot_item {
  list-style-image: url('assets/dot-item.svg');
}

.tooltip {
  background-color: white;
  position: relative;
}

.tooltip::after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  left: 16px;
  top: 95%;
  border-width: 1rem;
  border-style: solid;
  border-color: white rgba(205, 133, 63, 0) rgba(205, 133, 63, 0) rgba(205, 133, 63, 0);
}

.vertical-lr {
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
}

.pulse {
  margin: 11px 0px 0px -12px;
  z-index: -2;
}

.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  box-shadow: 0 0 1px 2px #46f7ad;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.gm-style-iw + div {
  display: none;
}

.gm-ui-hover-effect {
  display: none !important;
}

div.gm-style-iw.gm-style-iw-c {
  @apply top-2;
}

div.gm-style-iw-d {
  @apply p-0 m-0;
}
